import React from "react"
import { graphql, Link } from 'gatsby';
import styled from 'styled-components'

import Layout from '../components/layout'
import ProjectPreview from '../components/project-preview'
import Hero from '../components/Hero'
import Checkbox from '../components/Checkbox';

const StyledTags = styled.div`
  margin: 2rem auto -1rem auto;
  width: 95vw;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  max-width:95vw;
  flex-shrink:inherit;

  /* Desktop styles */
  @media(min-width: 700px) {
    width: 80.5ch;
  }

`

const PaginationLinks = styled.div`
    margin: 1rem auto;
    max-width: 100%;
    width: 78ch;
    display: flex;
    justify-content: space-around;

    button{
        color: ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.body};
        border: 0.5px solid ${({ theme }) => theme.text};
        border-radius: 3px;
        padding: 6px 16px;
        font-size: .7rem;
    }    
`



const ListOfProjects = ({ data, pageContext }) => {

  const projects = data.allContentfulProject.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const operationsTagsData = ['Toate', 'Proiectare', 'Management', 'Executie'];
  const [optags, setOpTags] = React.useState({ selectedOpTags: ['Toate'] })
  const [proiecteFiltru, setProiecteFiltru] = React.useState([])


  const { selectedOpTags } = optags;

  React.useEffect(() => {
    let searchedProjects = [];
    if (!projects.length) return;

    if (selectedOpTags[0] === 'Toate') {
      setProiecteFiltru(projects)
    } else if (selectedOpTags[0] === 'Proiectare') {
      searchedProjects = projects.filter(({ node: project }) => project.tags.includes('Proiectare'.toLowerCase()))
      setProiecteFiltru(searchedProjects)

    }
    else if (selectedOpTags[0] === 'Management') {
      searchedProjects = projects.filter(({ node: project }) => project.tags.includes('Management'.toLowerCase()))
      setProiecteFiltru(searchedProjects)
    }
    else if (selectedOpTags[0] === 'Executie') {
      searchedProjects = projects.filter(({ node: project }) => project.tags.includes('Executie'.toLowerCase()))
      setProiecteFiltru(searchedProjects)
    }

  }, [selectedOpTags]);

  const handleOpTagChange = async (tag, checked) => {

    const nextSelectedOpTags = checked ? [tag] : selectedOpTags.filter(t => t !== tag);
    console.log('You are interested in OPtags: ', nextSelectedOpTags);
    await setOpTags({ selectedOpTags: nextSelectedOpTags });
  }


  return (
    <Layout>
      <Hero />
      <StyledTags>
        {operationsTagsData.map(tag => (
          <label htmlFor={tag} key={tag}>
            <Checkbox
              key={tag}
              tag={tag}
              id={tag}
              name={tag}
              checked={selectedOpTags.indexOf(tag) > -1}
              onChange={checked => handleOpTagChange(tag, checked)} />

          </label>
        ))}
      </StyledTags>
      {
        proiecteFiltru.map(({ node: project }) => {
          const title = project.title;
          const category = project.category;
          const slug = project.slug;
          const tags = project.tags;
          const description = project.shortDescription;
          const imageData = project.images[0].fluid;
          return (

            <ProjectPreview
              key={slug}
              title={title}
              slug={slug}
              description={description}
              imageData={imageData}
              tags={tags}
              category={category}
            />
          )
        })
      }
      <PaginationLinks>
        {!isFirst && (
          <button>
            <Link to={prevPage} rel="prev">
              ← Previous Page
          </Link>
          </button>
        )}

        {!isLast && (
          <button>
            <Link to={nextPage} rel="next">
              More Projects →
          </Link>
          </button>
        )}
      </PaginationLinks>
    </Layout>

  );
}
export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
     allContentfulProject(
        sort: {fields: createdAt, order: DESC}
        skip: $skip
        limit: $limit
     ) {
        edges {
          node {
            slug
            tags
            title
            shortDescription
            images {
              fluid (maxWidth: 3080, quality: 100){
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `


export default ListOfProjects;