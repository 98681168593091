import React from 'react'
import styled from 'styled-components'

const Hero = styled.div`
  margin: 1rem auto;
  width: 93vw;
  max-width: 96%;

  /*desktop */
  @media (min-width: 750px) {
    width: 80ch;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-content: center;
    justify-content: center;
    margin-top: -1rem;

  }
`
const HeroGroup = styled.div`
  margin: 1rem 0rem 2.7rem 0rem;
  text-align: left;
  
  /*desktop */
  @media (min-width: 750px) {
    margin-top: 1rem;
    margin-bottom: .1rem;
    width:100vw;
    max-width: 85%;
  }

  h1 {
    margin-top:.3rem;
    margin-bottom:.3rem;
    color: ${({ theme }) => theme.text};
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    
    /*desktop */
    @media (min-width: 750px) {
    font-size: 1.7rem;

    }
  }
  p {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 15px;
    opacity: 0;
    animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);

    /*desktop */
    @media (min-width: 750px) {
      font-size: 1.2rem;

    }
  }

  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const HeroSection = () => (
  <Hero>
    <HeroGroup>
      <h1>Construcții și Management de Proiect</h1>
      <p>Simplicitate, Transparență și Viziune Globală</p>
    </HeroGroup>
  </Hero>
)

export default HeroSection
