import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { fadeInUp } from '../utils/m-styled-animations'

// border lighttheme --gray-400

const ProjectCard = styled.div`
  border: solid 1px ${({ theme }) => theme.previewCardBorder}; 
  border-radius: 5px;
  margin: 3rem auto;
  max-width: 95%;
  width: 80ch;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.3rem;
  background: ${({ theme }) => theme.text};
  animation: ${fadeInUp} 2s;
  position: relative;

  /*desktop*/
  @media (min-width: 700px) {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
        
  }
`

const CardTextArea = styled.div`
  margin: 1rem 1rem;
  h2 {
  color: white;
    padding-top: 1rem;
    font-size: 1.4rem;
  }
  p {
  color: white;

    font-size: 0.8rem;
    margin-bottom:2rem;
  }
  button {
    color: white;
    background: ${({ theme }) => theme.text};
    border: 0.5px solid white;
    border-radius: 3px;
    padding: 6px 16px;
    margin-top: 1rem;
    font-size: 0.8rem;
    position: absolute;
    left:1rem;
    bottom:1rem;
    a {
      color: white;
    }
  }
/* Desktop styles */
  @media(min-width: 700px) {
    margin: 1.3rem 1.3rem;
    button{
      position: absolute;
      left:1.3rem;
      bottom:1.3rem;
    }
}
`
const PillContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;

  span {
    color: ${({ theme }) => theme.text};
    background: #d6ead7;
    padding: 0px 3px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-size: 0.45rem;
    font-weight: 600;
    line-height: 165%;
    margin: 0 3px;
  }
`

const StyledImg = styled(Img)`
  max-width: 100%;
  border-radius: 5px;
  margin: 5px 5px;

  @media(min-width: 700px) {
    grid-column: 2;
    margin: 1rem 1rem;
}
`
const Imagediv = styled.div`
@media(min-width: 700px) {
  grid-column: 2;
}
`


const ProjectPreview = ({
  slug,
  title,
  description,
  imageData,
  tags,
}) => {
  return (
    <ProjectCard>
      <Imagediv>
        <Link to={`/${slug}/`}>

          <StyledImg loading="eager" fluid={imageData} alt={title} />
        </Link>
      </Imagediv>

      <CardTextArea>
        <Link to={`/${slug}/`}>
          <PillContainer>
            {tags.map((tag) => {
              return (
                <span key={tag}>{tag}</span>
              )
            })}

          </PillContainer>
          <h2>
            {title}
          </h2>
          <p className="description">{description}</p>
        </Link>
        <button>
          <Link to={`/${slug}/`}>Read more &rarr;</Link>
        </button>
      </CardTextArea>
    </ProjectCard>
  )
}

export default ProjectPreview
